import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { incrementCount } from '../apis/analytics';

const ButtonContainer = styled.div`
    padding: 12px 16px;
`;

const StyledButton = styled.button`
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    border-radius: 10px;
    border: 1px solid var(--Neutral-neutral4, #DCDCDC);
    background: var(--Main-white, #FFF);
    font-weight: 500;
    font-size: 1rem;
    color: var(--Main-deepBlue, #020C19);
`;


function SeeMoreButton({ path }) {
    const navigate = useNavigate();
    const urlSearch = new URLSearchParams(window.location.search);
    const searchQuery = urlSearch.get('q') || '';

    const handleSeeMoreClick = async () => {
        await incrementCount("ClickSeeMoreCount")
        switch(path) {
            case 'all':
                navigate(`/all?q=${searchQuery}`);
                break;
            case 'shorts':
                navigate(`/shorts?q=${searchQuery}&selectedTab=shorts`);
                break;
            case 'youtube':
                navigate(`/youtube?q=${searchQuery}&selectedTab=youtube`);
                break;
            case 'price-comparison':
                navigate(`/price-comparison?q=${searchQuery}`);
                break;
            case 'review':
                navigate(`/review?q=${searchQuery}&selectedTab=review`);
                break;
            case 'instagram':
                navigate(`/instagram?q=${searchQuery}&selectedTab=instagram`);
                break;
            case 'community':
                navigate(`/community?q=${searchQuery}`);
                break;
            default:
                navigate(`/`);
                break;
        }
    };

    return (
        <ButtonContainer>
            <StyledButton onClick={handleSeeMoreClick}>
                See More
            </StyledButton>
        </ButtonContainer>
    );
}

export default SeeMoreButton;
