import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getYoutubeShortsList } from '../apis/shorts';
import styled from 'styled-components';
import { incrementCount } from '../apis/analytics';
// import YouTube from 'react-youtube';

const ShortsSecondary = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; 
    color: var(--text-muted, #9CA0AC);
    padding: 12px 16px;
`;

const ShortsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 8px;
    column-gap: 8px;
    flex: 1;
`;

const ShortItem = styled.div`
    cursor: pointer; // 마우스 커서 변경
    max-width: calc(50% - 16px); // 최대 너비 제한
    display: flex;

    img {
        width: 160px; // 이미지 너비 설정
        height: 285px; // 이미지 높이 설정
        object-fit: cover; // 이미지 비율 유지하며 채우기
        border-radius: 12px;
    }
`;

function ShortsList() {
    const navigate = useNavigate();
    const urlSearch = new URLSearchParams(window.location.search);
    const [searchQuery, setSearchQuery] = useState(urlSearch.get('q') || '');
    const [items, setItems] = useState([]);

    useEffect(() => {
        async function run() {
            const shortsSearch = await getYoutubeShortsList(searchQuery);
            setItems(shortsSearch);
        }

        if (searchQuery) {
            run();
        }
    }, [searchQuery])

    const handleShortClick = async (id) => {
        navigate(`/shorts/${id}?q=${searchQuery}&selectedTab=shorts`);

        await incrementCount("ClickShortsCount")
    };

    return (
        <>
            <ShortsSecondary>
                <div>{items.length} Posts</div>
            </ShortsSecondary>
            <ShortsGrid>
                {items.map((short) => (
                    <ShortItem key={short.id} onClick={() => handleShortClick(short.id)}>
                        <img src={`https://img.youtube.com/vi/${short.id}/0.jpg`} alt="thumbnail" />
                        {/* <YouTube videoId={short.id} /> */}
                    </ShortItem>
                ))}
            </ShortsGrid>
        </>
    );
}

export default ShortsList;
