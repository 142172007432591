import React from "react";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import All from "./pages/All";
import ShortsPage from "./pages/ShortsPage";
import FullScreenVideo from "./components/FullScreenVideo";
import YoutubePage from "./pages/YoutubePage";
import FullScreenShort from "./components/FullScreenShort";
import InstagramPage from "./pages/InstagramPage";
import ReviewPage from "./pages/ReviewPage";
import SplitViewPage from "./pages/SplitViewPage";
import FullScreenInstagramPost from "./components/FullScreenInstagramPost";
import { RecoilRoot } from "recoil";
function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/all" element={<All />} />
          <Route path="/shorts" element={<ShortsPage />} />
          <Route path="/shorts/:id" element={<FullScreenShort />} />
          <Route path="/youtube" element={<YoutubePage />} />
          <Route path="/youtube/:id" element={<FullScreenVideo />} />
          <Route path="/instagram" element={<InstagramPage />} />
          <Route path="/instagram/:id" element={<FullScreenInstagramPost />} />
          <Route path="/review" element={<ReviewPage />} />
          <Route path="/split" element={<SplitViewPage />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
