import axios from "axios";

async function getYoutubeVideos(query){
  const response = await axios(`/api/v1/youtube-videos?query=${query}`);
  return response.data;
}

async function getYoutubeVideosList(query){
  const response = await axios(`/api/v1/youtube-videos-list?query=${query}`);
  return response.data;
}

export { getYoutubeVideos, getYoutubeVideosList };
