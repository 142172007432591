import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { deleteSearchHistory, getSearchHistory, postSearchHistory } from "../apis/searchHistory";
import FixedTabBar from "./FixedTabBar";
import useSearchHistory from "../hooks/useSearchHistory";
import LoginButton from "./LoginButton";
import { incrementCount } from "../apis/analytics";

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  // padding: 10px 16px;
  /* Add any additional styles you need for the container */
`;

const SearchInput = styled.input`
  width: 100%;
  height: 54px;
  border-radius: 550px;
  background: var(--Neutral-neutral5, #f2f2f2) url("/svg/discover.svg") no-repeat 16px center;
  padding: 10px;
  padding-left: 48px;
  font-size: 16px;
  //   border-radius: 4px;
  /* Add any additional styles you need for the input */

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  &:focus {
    outline: none; // This line removes the border on focus
  }
`;

const SearchBarContainer = styled.div`
  padding: 10px 16px;
  /* Add any additional styles you need for the search bar container */
  &.focused {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 16px 0px;
    display: flex;
    justify-content: center;
    // transform: translateY(calc(-50vh + 74px)); /* Adjust the value according to the design */
    z-index: 10;
    border-bottom: 1px solid var(--Main-deepPurple, #6800ec);
    /* Add any additional styles you need for the focused state */

    input {
      height: 40px;
      width: calc(100% - 24px);
      border-radius: 10px;
    }
  }
`;

const SearchHistory = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  // border: 1px solid #ccc;
  border-top: none;
  width: 100%;
  height: 100vh;
  z-index: 10;
  /* Add any additional styles you need for the search history */
`;

const FirstHistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const FirstHistoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 22px;
`;

const StyledScheduleImage = styled.img``;

const HistoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-left: 44px;
  padding-right: 22px;
  // border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
  /* Add any additional styles you need for history items */
`;

const SearchBar = ({ activeTab }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { searchHistory } = useSearchHistory();
  const inputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // 포커스 상태 관리 및 라우팅 처리
    const handleBackButton = () => {
      if (isFocused) {
        navigate("/");
        setIsFocused(false);
        inputRef.current?.blur();
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isFocused]);

  const handleFocus = () => {
    navigate("?isFocus=true");
    setIsFocused(true);

    setTimeout(() => {
      const searchBarPosition = inputRef.current.getBoundingClientRect().top;
      window.scrollTo({
        top: searchBarPosition,
        behavior: "smooth",
      });
    }, 300);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyPress = async (e) => {
    // iOS에서 Enter 키가 13으로 인식됩니다.
    if ((e.key === "Enter" || e.keyCode === 13 || e.which === 13) && searchQuery.trim()) {
      await postSearchHistory(searchQuery);
      const tab = activeTab.toLowerCase();
      navigate(`/all?q=${encodeURIComponent(searchQuery)}&selectedTab=${tab}`);
      setIsFocused(false);

      await incrementCount("SearchCount");
    }
  };

  const handleHistoryClick = async (term) => {
    setSearchQuery(term);
    await postSearchHistory(term);
    const tab = activeTab.toLowerCase();
    navigate(`/all?q=${encodeURIComponent(term)}&selectedTab=${tab}`);
    setIsFocused(false);

    await incrementCount("SearchCount");
  };

  const removeHistoryItem = async (term) => {
    await deleteSearchHistory(term);
  };
  return (
    <>
      {!isFocused && <LoginButton />}
      <SearchContainer>
        <SearchBarContainer className={isFocused ? "focused" : ""}>
          <SearchInput ref={inputRef} type="search" value={searchQuery} onChange={handleSearch} onFocus={handleFocus} onKeyPress={handleKeyPress} placeholder="Search for Brand or Product" />
          {isFocused && (
            <SearchHistory>
              {searchHistory
                .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                .map((item, index) => {
                  if (index === 0) {
                    return (
                      <FirstHistoryItem key={index}>
                        <FirstHistoryContainer>
                          <StyledScheduleImage src="/svg/schedule.svg" />
                          <div onClick={() => handleHistoryClick(item.searchTerm)}>{item.searchTerm}</div>
                        </FirstHistoryContainer>
                        <img src="/svg/delete.svg" onClick={() => removeHistoryItem(item.searchTerm)} />
                      </FirstHistoryItem>
                    );
                  } else {
                    return (
                      <HistoryItem key={index}>
                        <div onClick={() => handleHistoryClick(item.searchTerm)}>{item.searchTerm}</div>
                        <img src="/svg/delete.svg" onClick={() => removeHistoryItem(item.searchTerm)} />
                      </HistoryItem>
                    );
                  }
                })}
            </SearchHistory>
          )}
        </SearchBarContainer>
      </SearchContainer>
      {!isFocused && <FixedTabBar />}
    </>
  );
};

export default SearchBar;
