// 체크 필요
export function isEqual(obj1, obj2) {
    const obj1Keys = Object.keys(obj1).filter(key => (key !== 'createdAt') && (key !== 'uid'));
    const obj2Keys = Object.keys(obj2).filter(key => (key !== 'createdAt') && (key !== 'uid'));

    if (obj1Keys.length !== obj2Keys.length) {
        return false;
    }

    for (let key of obj1Keys) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}