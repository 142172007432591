import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getYoutubeVideosList } from '../apis/youtube';
import { addBookMark } from '../apis/bookmark';
import useBookmarks from '../hooks/useBookMarks';
import { isEqual } from '../apis/common';
import { incrementCount } from '../apis/analytics';
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoginModalOpenState, userMeState } from "../stores/authStore";
import _ from "lodash";

const VideosGrid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const YoutubeItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    pointer: cursor;
`;

const YoutubeInformation = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
`;

const YoutubeChannelTitle = styled.span`
    font-size: 14px;
    font-weight: 700;
    color: var(--Main-deepBlue, #020C19);
`;

const YoutubeTitle = styled.span`
    color: var(--Main-deepBlue, #020C19);
    font-size: 14px;
`;

const StyledImage = styled.img`
    width: 100%; // 너비를 100%로 설정
    height: 231px; // 높이 설정
    object-fit: cover; // 이미지 비율 유지하며 채우기
    
    @media (min-width: 768px) {
        width: 730px;
        height: 360px;
        object-fit: cover;
    }
`;

const BookmarkImage = styled.div`
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;

    @media (min-width: 768px) {
        left: 688px;
        right: 0px;
    }
`;

const BookMarkButton = ({ bookmark }) => {
    const { bookmarks } = useBookmarks();
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(isLoginModalOpenState);
    const userMe = useRecoilValue(userMeState);

    useEffect(() => {
        setIsBookmarked(bookmarks.find((item) => isEqual(item, bookmark)));
    }, [bookmarks])


    const handleClickBookMarkImage = useCallback(
        (e) => {
            e.stopPropagation();
            // 로그인이 안된 상태로 북마크를 하지 못하도록 로그인창을 띄움
            if (_.isEmpty(userMe)) {
                return setIsLoginModalOpen(true);
            }
            addBookMark(bookmark);
        },
        [bookmark, userMe]
    );

    const iconPath = isBookmarked ? "/svg/bookmark-filled.svg" : "/svg/bookmark.svg";

    return (
        <BookmarkImage onClick={handleClickBookMarkImage}>
            <img src={iconPath} alt=">" />
        </BookmarkImage>
    );
}

function YoutubeVideoList() {
    const navigate = useNavigate();
    const urlSearch = new URLSearchParams(window.location.search);
    const [searchQuery, setSearchQuery] = useState(urlSearch.get('q') || '');
    const [items, setItems] = useState({ items: [] });
    const itemRefs = useRef([]);
    const bookmark = {
        type: 'youtube',
        query: searchQuery,
        isSeeMore: true
    }

    const verticalScrollItemId = urlSearch.get('verticalScrollItemId');

    useEffect(() => {
        async function run() {
            const videosList = await getYoutubeVideosList(searchQuery);
            setItems(videosList);
        }

        if (searchQuery) {
            run();
        }
    }, [searchQuery]);

    useEffect(() => {
        if (verticalScrollItemId && itemRefs.current[verticalScrollItemId]) {
            itemRefs.current[verticalScrollItemId].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [items, verticalScrollItemId]);

    const handleVideoClick = async (id) => {
        navigate(`/youtube/${id}?q=${searchQuery}&selectedTab=youtube`);

        await incrementCount("ClickYoutubeCount")
    };

    return (
        <>
            <VideosGrid>
                {items.items.map((item, index) => (
                    <YoutubeItem ref={el => itemRefs.current[index] = el} key={item.id}>
                        <BookMarkButton bookmark={{...bookmark, verticalScrollItemId: index}} />
                        <StyledImage onClick={() => { handleVideoClick(item.id) }} src={`https://img.youtube.com/vi/${item.id}/0.jpg`} alt="thumbnail" />
                        <YoutubeInformation style={{ "padding-inline": "16px" }}>
                            <YoutubeChannelTitle>{item.channelTitle}</YoutubeChannelTitle> &ensp; &ensp;
                            <YoutubeTitle>{item.title}</YoutubeTitle>
                        </YoutubeInformation>
                    </YoutubeItem>
                ))}
            </VideosGrid>
        </>
    );
}

export default YoutubeVideoList;
