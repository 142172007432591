import React from "react";
import styled from "styled-components";

const BottomSheet = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const BottomSheetContainer = styled.div`
  width: 100%;
  max-width: 343px; // 최대 너비를 343px로 설정
  margin: 0 16px; // 중앙 정렬
  position: relative; // 배치를 위한 위치 설정
`;

const BottomSheetLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center; // 수평 중앙 정렬
  align-items: center; // 수직 중앙 정렬
  z-index: 10000;
  background: rgba(0, 0, 0, 0.2); // 반투명 배경 추가
`;

const SignInButton = styled.button`
  background: #1a73e8;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #357ae8;
  }
`;

const BottomSheetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
`;

const BottomSheetTitle = styled.div`
  display: flex;
  align-items: center;
`;

const BottomSheetText = styled.span`
  margin-left: 8px;
  color: var(--Neutral-neutral2, #838383);
  font-size: 14px;
  font-weight: 500;
`;

const CloseButton = styled.img`
  cursor: pointer;
`;

const LoginModal = (props) => {
  return (
    <BottomSheetLayout onClick={props.handleClosePopup}>
      <BottomSheetContainer onClick={(e) => e.stopPropagation()}>
        <BottomSheet>
          <BottomSheetHeader>
            <BottomSheetTitle>
              <img src="/svg/google.svg" alt="구글" />
              <BottomSheetText>Sign in to Diggit with Google</BottomSheetText>
            </BottomSheetTitle>
            <CloseButton src="/svg/close.svg" onClick={props.handleClosePopup} />
          </BottomSheetHeader>
          <SignInButton onClick={props.handleSignIn}>Continue with Google</SignInButton>
        </BottomSheet>
      </BottomSheetContainer>
    </BottomSheetLayout>
  );
};

export default LoginModal;
