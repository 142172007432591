import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import styled from 'styled-components';
import { addUser } from '../apis/analytics';
import { auth } from '../firebase';

const SpeechBubbleLayout = styled.div`
  position: absolute;
  border-radius: 12px;
  background: var(--Main-white, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px;
  width: fit-content;
  left: 93px;
  top: 400px;
  display: flex;
  justify-content: center;
`;

const SpeechBubbleText = styled.div`
  color: var(--Main-deepBlue, #020c19);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  pointer: cursor;
`;

const Polygon = styled.img`
  position: absolute;
  left: 150px;
  top: 95%; // 위치를 SpeechBubbleLayout의 바로 아래로 설정
`

const EmphasizedText = styled.span`
  color: var(--Main-deepPurple, #6800ec);
  font-size: 14px;
  font-weight: 700;
`;

const SpeechBubble = () => {
  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      addUser(auth.currentUser.uid);
      localStorage.setItem("user", auth);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SpeechBubbleLayout>
      <SpeechBubbleText>
        <div>
          <EmphasizedText onClick={handleSignIn}>1초만에 로그인</EmphasizedText>하면 책갈피를
        </div>
        <div>무제한으로 기록할 수 있어요!</div>
      </SpeechBubbleText>
      <Polygon src="/svg/polygon.svg" alt="" />
    </SpeechBubbleLayout>
  );
};

export default SpeechBubble;
