import '../global.css'

import { useState } from 'react';
import ResultSearchBar from '../components/ResultSearchBar';
import InstagramList from '../components/InstagramList';
import FixedTabBar from '../components/FixedTabBar';
import { FixedBookMarkButton } from '../components/FixedBookMarkButton';
import { useRecoilState } from 'recoil';
import LoginModal from '../components/LoginModal';
import { isLoginModalOpenState } from '../stores/authStore';
import { auth } from '../firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { addUser } from '../apis/analytics';

function InstagramPage() {
    const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(isLoginModalOpenState);
    const searchParams = new URLSearchParams(window.location.search);
    const [activeTab, setActiveTab] = useState(searchParams.get('selectedTab') || 'all');
    const bookmark = {
        type: 'instagram',
        query: searchParams.get('q') || '',
        isSeeMore: true
    };

    const handleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            addUser(result.user.uid);
            localStorage.setItem("user", result.user.uid);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <ResultSearchBar activeTab={activeTab} />
            {/* <ScrollTabs activeTab={"instagram"} setActiveTab={setActiveTab}/> */}
            <InstagramList />
            <FixedBookMarkButton bookmark={bookmark} />
            <FixedTabBar />
            {isLoginModalOpen && <LoginModal handleClosePopup={() => setIsLoginModalOpen(false)} handleSignIn={handleSignIn} />}
        </>
    )
}

export default InstagramPage;