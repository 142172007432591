// TabBar.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getBookMarks, goBookMarkPath } from "../apis/bookmark";
import useBookmarks from "../hooks/useBookMarks";
import { SplitButton } from "./SplitButton";
import { incrementCount } from "../apis/analytics";
import { useParams, useLocation } from "react-router-dom";

const Layout = styled.div`
  height: 50px;
  position: fixed;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  max-width: calc(100% - 24px);
  height: 100%;
`;

const TabBarContainer = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 100;
  height: 100%;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  overflow-x: scroll;
  padding: 0 8px;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  background-color: ${(props) => (props.isSelected ? "#020C19" : "transparent")};
  color: ${(props) => (props.isSelected ? "#fff" : "#020C19")};
  border-radius: 12px;
  height: 36px;
  box-sizing: border-box;
`;

const Logo = styled.img`
  margin-right: 4px;
`;

const FixedTabBar = ({ style }) => {
  const { bookmarks } = useBookmarks();
  const { id } = useParams();
  const urlSearch = new URLSearchParams(window.location.search);
  const searchQuery = urlSearch.get("q");
  const horizontalScrollItemId = urlSearch.get("horizontalScrollItemId");
  const verticalScrollItemId = urlSearch.get("verticalScrollItemId");
  const location = useLocation(); 
  const selectedTab = urlSearch.get("selectedTab");

  // Function to handle tab click
  const handleTabClick = async (bookmark) => {
    const path = goBookMarkPath(bookmark);
    window.location.href = path; // Navigate to the generated path
    await incrementCount("ClickBookmarkCount");
  };

  return (
    <Layout style={style}>
      <Container>
        <TabBarContainer>
          {bookmarks.map((bookmark, index) => {
            let logoSrc = null;
            const isSelected = (selectedTab === bookmark.type || (selectedTab === "all" && bookmark.type === "homepage")) &&
            searchQuery === bookmark.query &&
            (!bookmark.horizontalScrollItemId || Number(horizontalScrollItemId) === Number(bookmark.horizontalScrollItemId)) &&
            (!bookmark.verticalScrollItemId || Number(verticalScrollItemId) === Number(bookmark.verticalScrollItemId)) &&
            (!bookmark.shortsId || id === Number(bookmark.shortsId)) &&
            (!bookmark.youtubeId || id === Number(bookmark.youtubeId)) &&
            (!bookmark.instagramId || id === Number(bookmark.instagramId)) &&
            (!bookmark.isSeeMore || bookmark.isSeeMore === (location.pathname !== "/all"))

            if (bookmark.type === "instagram" && isSelected) {
              logoSrc = "/svg/selectedInstagram.svg";
          } else {
              logoSrc = {
                instagram: "/svg/instagram.svg",
                homepage: "/svg/homepage.svg",
                shorts: "/svg/youtubeShorts.svg",
                price: "/svg/price.svg",
                community: "/svg/naver.svg",
                review: "/svg/naver.svg",
                youtube: "/svg/youtube.svg",
              }[bookmark.type];
          }

            return (
              <Tab key={index} onClick={() => handleTabClick(bookmark)} isSelected={isSelected}>
                {logoSrc && <Logo src={logoSrc} alt={bookmark.type} />}
                {bookmark.query}
              </Tab>
            );
          })}
          <SplitButton />
        </TabBarContainer>
      </Container>
    </Layout>
  );
};

export default FixedTabBar;
