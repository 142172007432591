import axios from "axios";

async function getYoutubeShorts(query){
  const response = await axios(`/api/v1/youtube-shorts-list?query=${query}`);
  // const sortedData = response.data.sort((a, b) => {
  //   return b.views - a.views;
  // });
  return response.data.slice(0, 5);
}

async function getYoutubeShortsList(query){
  const response = await axios(`/api/v1/youtube-shorts-list?query=${query}`);
  return response.data;
}

export { getYoutubeShorts, getYoutubeShortsList };
