import { db } from "../firebase";
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';

async function incrementCount(name) {
    const docRef = doc(db, 'analytics', name);

    try {
        // 문서를 읽어옵니다.
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // count 필드를 1만큼 증가시킵니다.
            await updateDoc(docRef, {
                count: docSnap.data().count + 1
            });
        } else {
            await setDoc(docRef, {
                count: 1
            });
        }
    } catch (error) {
        console.error('Error updating document: ', error);
    }
}

async function getCount(name) {
    const docRef = doc(db, 'analytics', name);

    try {
        // 문서를 읽어옵니다.
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // count 필드의 값을 반환합니다.
            return docSnap.data().count;
        } else {
            console.log('No such document!');
            return null;
        }
    } catch (error) {
        console.error('Error reading document: ', error);
        return null;
    }
}

async function addUser(uid) {
    const docRef = doc(db, 'users', uid);

    try {
        // 문서를 읽어옵니다.
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            // 해당 uid를 가진 문서가 없으면 새로운 문서를 생성합니다.
            await setDoc(docRef, { uid: uid });
            console.log(`User ${uid} added.`);
        } else {
            console.log(`User ${uid} already exists.`);
        }
    } catch (error) {
        console.error('Error accessing document: ', error);
    }
}

export { incrementCount, getCount, addUser };