import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { incrementCount } from '../apis/analytics';

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
`;

const TitleTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px; // This adds the 6px gap between the SVG and the text
`;

const TitleText = styled.div`
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
`

const ChevronImage = styled.div`
    display: flex;
`

const Title = ({ title }) => {
    const navigate = useNavigate();
    const searchURL = new URLSearchParams(window.location.search);
    const searchQuery = searchURL.get('q');

    const data = [
        {
            name: 'Shorts', path: `/shorts`,
        },
        {
            name: 'Youtube', path: `/youtube`,
        },
        {
            name: 'Instagram', path: `/instagram`,
        }
    ]

    const handleGoSeeMore = async () => {
        data.map((item) => {
            if (item.name === title) {
                const path = item.path;
                navigate(`${path}?q=${searchQuery}&selectedTab=${title}`)
            }
        })

        await incrementCount("ClickSeeMoreCount");
    }

    return (
        <TitleContainer onClick={handleGoSeeMore}>
            <TitleTextContainer>
                {title === 'Shorts' && <img src="/svg/shorts.svg" alt="Shorts icon" style={{ width: '19px', height: '24px' }} />}
                <TitleText>{title}</TitleText>
            </TitleTextContainer>
            <ChevronImage><img src="/svg/chevron.svg" alt=">" /></ChevronImage>
        </TitleContainer>
    );
}

export default Title;