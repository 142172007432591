import { useEffect, useRef, useState } from "react";
import YouTube from "react-youtube";
import Title from "./Title";
import SeeMoreButton from "./SeeMoreButton";
import { Padding } from "./Padding";
import { getYoutubeShorts, getYoutubeShortsList } from "../apis/shorts";
import styled from "styled-components";
import { BookMarkButton } from "./BookMarkButton";
import { useNavigate } from "react-router-dom";

// Component styles (same as Instagram, add if any are missing)
const ShortsFeedWrapper = styled.div`
  iframe {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
`;

const ShortsFeed = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding-left: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ShortsCard = styled.div`
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 12px;
  background: var(--Neutral-neutral5, #f2f2f2);
  gap: 12px;
`;

const ResultHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
`;

const TitleText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
`;

const ShortsText = styled.span`
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  width: 235px;
  color: var(--Main-deepBlue, #020c19);
  font-size: 14px;
  font-weight: 400;
`;

const Caption = styled.span`
  font-weight: normal;
`;

function Shorts() {
  const navigate = useNavigate();
  const urlSearch = new URLSearchParams(window.location.search);
  const [searchQuery, setSearchQuery] = useState(urlSearch.get("q") || "");
  const [searchResult, setSearchResult] = useState([]);
  const shortsFeedRef = useRef(null); // Add this line to create a ref
  const horizontalScrollItemId = parseInt(
    urlSearch.get("horizontalScrollItemId"),
    10
  );
  const bookmark = {
    type: "shorts",
    query: searchQuery,
    isSeeMore: false,
  };

  const opts = {
    width: "235px",
    height: "417.78px",
    playerVars: {
      autoplay: 0,
      mute: 0,
    },
  };

  useEffect(() => {
    async function run() {
      const shortsSearch = await getYoutubeShortsList(searchQuery);
      setSearchResult(shortsSearch);
    }

    run();
  }, [searchQuery]);

  const onClickShort = (id) => {
    navigate(`/shorts/${id}?q=${searchQuery}&selectedTab=shorts`);
  };

  useEffect(() => {
    // Scroll to the item when the component mounts or verticalScrollItemId changes
    if (
      shortsFeedRef.current &&
      !isNaN(horizontalScrollItemId) &&
      urlSearch.get("selectedTab") === "shorts"
    ) {
      const targetElement =
        shortsFeedRef.current.children[horizontalScrollItemId];
      if (targetElement) {
        shortsFeedRef.current.scrollLeft = targetElement.offsetLeft;
      }
    }
  }, [horizontalScrollItemId, searchResult]);

  return (
    <ShortsFeedWrapper id="shorts">
      <Title title={"Shorts"} />
      <ShortsFeed ref={shortsFeedRef}>
        {searchResult &&
          searchResult.slice(0, 8).map((item, index) => (
            <ShortsCard
              key={index}
              onClick={() => {
                onClickShort(item.id);
              }}
            >
              <ResultHeader>
                <TitleText>
                  {item.channel.split("/")[item.channel.split("/").length - 1]}
                </TitleText>
                <BookMarkButton
                  bookmark={{ ...bookmark, horizontalScrollItemId: index }}
                />
              </ResultHeader>
              {searchResult.length > 0 && item.id !== null && (
                <YouTube videoId={item.id} opts={opts} />
              )}
              <ShortsText>
                {item.channelTitle}
                <Caption>{item.title}</Caption>
              </ShortsText>
            </ShortsCard>
          ))}
      </ShortsFeed>
      <SeeMoreButton path="shorts" />
      {/* <Padding /> */}
    </ShortsFeedWrapper>
  );
}

export default Shorts;
