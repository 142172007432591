import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { auth } from "../firebase";
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
import LoginModal from "./LoginModal";
import { addUser } from "../apis/analytics";

const UserIcon = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  /* ... your styles for the user icon ... */
`;

const Button = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 10px;
  border: 1px solid var(--Neutral-neutral4, #dcdcdc);
  background: var(--Main-white, #fff);
  color: var(--Main-deepBlue, #020c19);
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const LoginButton = () => {
  const [user, setUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      addUser(result.user.uid);
      setUser(result.user);
      setShowPopup(false);
      localStorage.setItem("user", result.user.uid);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeUser = async () => {
    const checkLogout = window.confirm("로그아웃 하시겠습니까?");
    if (checkLogout) await signOut(auth);
    localStorage.setItem("user", null);
    // const provider = new GoogleAuthProvider();
    // try {
    //   const result = await signInWithPopup(auth, provider);
    //   setUser(result.user);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {user ? <UserIcon src={user.photoURL} alt="User" onClick={handleChangeUser} /> : <Button onClick={handleOpenPopup}>Sign up</Button>}

      {showPopup && <LoginModal handleClosePopup={handleClosePopup} handleSignIn={handleSignIn} />}
    </>
  );
};

export default LoginButton;
