import styled from "styled-components";
import Title from "./Title";
import { useEffect, useState } from "react";
import { getNaverShoppingReviews, getNaverShoppingReviewPage } from "../apis/naverShopping";
import { Padding } from "./Padding";
import SeeMoreButton from "./SeeMoreButton";
import TitleWithBookMark from "./TitleWithBookMark";
import { incrementCount } from "../apis/analytics";

// Styled-components
const ReviewContainer = styled.div`
  display: ${({ isSelected }) => (isSelected ? "none" : "flex")};
  flex-direction: column;
  // overflow-y: scroll;
  max-height: ${({ isSelected }) => (isSelected ? "0" : "100%")}; // 선택된 아이템이 있을 경우 높이를 0으로 설정
`;

const ReviewItem = styled.div`
  border-bottom: 1px solid var(--Neutral-neutral5, #f2f2f2);
  padding: 16px;
  display: flex;
  gap: 16px; // 각 항목 사이의 간격
  cursor: pointer; // 클릭 가능한 아이템을 나타냄
  align-items: center;
`;

const ReviewImage = styled.img`
  width: 120px; // 이미지 너비
  height: 120px; // 이미지 높이
  object-fit: cover; // 이미지 비율 유지
  border-radius: 8px; // 이미지 둥근 모서리
`;

const ReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ReviewSecondary = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--Neutral-neutral2, #838383);
`;

const ReviewTitle = styled.div`
  font-size: 16px;
  color: var(--Main-deepBlue, #020c19);
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const ReviewPrice = styled.div`
  font-size: 16px;
  color: var(--Neutral-neutral1, #020c19);
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

const SeeMoreReviewButton = styled.button`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  border-radius: 10px;
  background: var(--Main-deepPurple, #6800ec);
  width: 100%;
  font-size: 1rem;
  max-width: 500px;
`;

const IframeContainer = styled.div`
  position: relative;
  display: ${({ isSelected }) => (isSelected ? "block" : "none")}; // 선택된 아이템이 있을 경우에만 보여짐
  width: 100%;
  height: 50vh;
  border: none;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const BackBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 32px);
  padding: 12px 16px;
  background-color: white;
  border: none;
  z-index: 100;
`;

function Review() {
  const urlSearch = new URLSearchParams(window.location.search);
  const [searchQuery, setSearchQuery] = useState(urlSearch.get("q") || "");
  const [searchResult, setSearchResult] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [iframeSrc, setIframeSrc] = useState("");
  const [bookmark, setBookmark] = useState({
    type: "review",
    query: searchQuery,
    isSeeMore: false,
  });

  useEffect(() => {
    async function updateIframeSrc() {
      if (selectedItemId) {
        const selectedItem = searchResult.find((item) => item.productId === selectedItemId);
        if (selectedItem && (selectedItem.mallName === "네이버" || selectedItem.mallName === "쿠팡" || selectedItem.mallName === "티몬" || selectedItem.mallName === "G마켓")) {
          setIframeSrc(`https://msearch.shopping.naver.com/catalog/${selectedItemId}/reviews`);
        } else {
          try {
            setIframeSrc(`https://msearch.shopping.naver.com/catalog/${selectedItemId}/reviews`);
            // const reviewPageUrl = await getNaverShoppingReviewPage(selectedItemId);
            // setIframeSrc(reviewPageUrl.data.url);
          } catch (error) {
            console.error("Error fetching review page URL:", error);
            setIframeSrc("");
          }
        }
      }
    }

    updateIframeSrc();
  }, [selectedItemId, searchResult]);

  useEffect(() => {
    async function run() {
      if (searchQuery) {
        try {
          const response = await getNaverShoppingReviews(searchQuery);
          setSearchResult(response.data);
        } catch (error) {
          console.error("Error fetching Naver Shopping reviews:", error);
        }
      }
    }

    run();
  }, [searchQuery]);

  const handleItemClick = async (productId) => {
    setSelectedItemId(productId);

    await incrementCount("ClickSeeMoreCount");
  };

  return (
    <div id="review">
      <TitleWithBookMark title={"Review"} bookmark={bookmark} />
      {selectedItemId === null && (
        <ReviewContainer isSelected={selectedItemId !== null}>
          {searchResult.slice(0, 3).map((item, index) => (
            <ReviewItem key={index} style={index === 0 ? { paddingTop: "0px" } : index === 2 ? { borderBottom: "none", paddingBottom: "0px" } : {}}>
              <ReviewImage src={item.image} alt="Product" />
              <ReviewContent>
                <ReviewSecondary>Comments left on {item.mallName}</ReviewSecondary>
                <ReviewTitle dangerouslySetInnerHTML={{ __html: item.title }} />
                <ReviewPrice>₩ {item.lprice}원</ReviewPrice>
                <SeeMoreReviewButton onClick={() => handleItemClick(item.productId)}>See More Reviews</SeeMoreReviewButton>
              </ReviewContent>
            </ReviewItem>
          ))}
        </ReviewContainer>
      )}
      {selectedItemId !== null && (
        <IframeContainer isSelected={selectedItemId !== null}>
          <BackBackground>
            <img src="/svg/chevron.svg" style={{ transform: "rotate(180deg)" }} onClick={() => setSelectedItemId(null)} alt="Go back" />
          </BackBackground>
          <Iframe src={iframeSrc} frameBorder="0" allowFullScreen />
        </IframeContainer>
      )}
      <SeeMoreButton path="review" />
      <Padding />
    </div>
  );
}

export default Review;
