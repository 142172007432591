import "../global.css";

import Community from "../components/Community";
import Homepage from "../components/Homepage";
import Instagram from "../components/Instagram";
import PriceComparison from "../components/PriceComparison";
import Review from "../components/Review";
import ScrollTabs from "../components/ScrollTabs";
import Shorts from "../components/Shorts";
import YoutubeVideos from "../components/YoutubeVideos";
import ResultSearchBar from "../components/ResultSearchBar";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import FixedTabBar from "../components/FixedTabBar";
import styled from "styled-components";
import SpeechBubble from "../components/SpeechBubble";
import LoginModal from "../components/LoginModal";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import { getBookMarks } from "../apis/bookmark";
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoginModalOpenState, userMeState } from "../stores/authStore";
import { addUser } from "../apis/analytics";

const FixedLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10000;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 16px;
  bottom: 0;
  background: transparent;
  z-index: 100; // 필요한 경우 z-index 조정
`;

function All() {
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get("selectedTab") || "home");
  const [showBubble, setShowBubble] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(isLoginModalOpenState);

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("isFirstVisit") === null;
    if (isFirstVisit) {
      const timer = setTimeout(() => {
        localStorage.setItem("isFirstVisit", "false");
        setShowBubble(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleOverlayClick = () => {
    setShowBubble(false); // 오버레이 클릭 시 SpeechBubble 숨김
  };

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      addUser(result.user.uid);
      localStorage.setItem("user", result.user.uid);
    } catch (error) {
      console.error(error);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "price":
        return <PriceComparison />;
      case "community":
        return <Community />;
      default:
        return (
          <>
            {/* {showBubble && (
              <Overlay onClick={handleOverlayClick}>
                <SpeechBubble />
              </Overlay>
            )} */}
            <Homepage />
            <Shorts />
            <YoutubeVideos />
            <Review />
            <Instagram />
            {isLoginModalOpen && <LoginModal handleClosePopup={() => setIsLoginModalOpen(false)} handleSignIn={handleSignIn} />}
          </>
        );
    }
  };

  return (
    <>
      <FixedLayout>
        <ResultSearchBar activeTab={activeTab} />
        <ScrollTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      </FixedLayout>
      <div style={activeTab === "price" || activeTab === "community" ? { marginTop: "122px" } : { marginTop: "180px" }}>{renderTabContent()}</div>
      <FixedTabBar />
    </>
  );
}

export default All;
