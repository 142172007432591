import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getPostFromInstagramCollection, savePostToInstagramCollection } from "../apis/firebase-instagram";
import { addBookMark } from "../apis/bookmark";
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoginModalOpenState, userMeState } from "../stores/authStore";
import { auth } from '../firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import _ from "lodash";
import LoginModal from "./LoginModal";
import { addUser } from "../apis/analytics";

// Styled Components
const Layout = styled.div`
  // max-height: 100vh;
`;

const GoBackContainer = styled.div`
  display: flex;
  padding: 12px 16px;
`;

const PostLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 8px;
`;

const PostImageContainer = styled.div`
  position: relative;
  padding-bottom: 8px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const PostImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 12px;
  object-fit: cover;

  @media (min-width: 768px) {
    max-width: 20%;
  }
`;

const BookmarkImage = styled.img`
  position: absolute;
  bottom: 20px;
  right: 12px;

  @media (min-width: 768px) {
    right: calc(40% + 12px);
  }
`;

const PostDetails = styled.div`
  text-align: left;
  padding-top: 4px;
  padding-inline: 16px;
  padding-bottom: 4px;
`;

const PostCaptionContainer = styled.span`
  font-size: 14px;
  color: var(--Main-deepBlue, #020c19);
  font-weight: 400;
  margin-bottom: 8px;
`;

const PostCaption = styled.span`
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  //   white-space: pre-wrap; /* Wrap white-space as usual */
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.isExpanded ? "none" : "2")}; /* Number of lines to show */
  -webkit-box-orient: vertical;
`;

const MoreButton = styled.span`
  color: #007bff;
  cursor: pointer;
  margin-left: 4px;
`;

const PostStats = styled.div`
  font-size: 12px;
  padding-inline: 16px;
  color: var(--Main-deepBlue, #020c19);
`;

const PostDate = styled.span`
  margin-right: 8px;
  color: var(--Neutral-neutral2, #838383);
`;

const PostStatsItem = styled.span`
  margin-right: 8px;
  color: var(--Main-deepBlue, #020c19);
`;

const PostUsername = styled.span`
  color: var(--Main-deepBlue, #020c19);
  font-size: 14px;
  font-weight: 700;
`;

function FullScreenInstagramPost() {
  const { id } = useParams();
  const { state } = useLocation();
  const [post, setPost] = useState(state?.post);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(isLoginModalOpenState);
  const userMe = useRecoilValue(userMeState);

  useEffect(() => {
    const fetchPost = async () => {
      const data = await getPostFromInstagramCollection(id);
      if (!data.empty) {
        setPost(data);
      }
    };

    if (!state?.post) {
      fetchPost();
    }
  }, [id, state?.post]);

  const handleBookmarkClick = async () => {
    if (_.isEmpty(userMe)) {
      setIsLoginModalOpen(true);
      return;
    }

    await savePostToInstagramCollection({
      ...post,
      instagramId: id,
    });

    await addBookMark({
      type: "instagram",
      isSeeMore: true,
      instagramId: id,
      query: new URLSearchParams(window.location.search).get("q"),
      post: post,
    });

    // Additional logic after bookmarking can be added here
  };

  const toggleCaption = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      addUser(result.user.uid);
      localStorage.setItem("user", result.user.uid);
    } catch (error) {
      console.error(error);
    }
  };

  if (!post) {
    return <div>Loading post...</div>;
  }

  return (
    <>
      <Layout>
        <GoBackContainer>
          <img src="/svg/chevron.svg" style={{ transform: "rotate(180deg)" }} onClick={() => window.history.back()} alt="Go back" />
        </GoBackContainer>
        <PostLayout>
          <PostImageContainer>
            <PostImage src={post.thumbnail_url} alt="Post thumbnail" />
            <BookmarkImage src="/svg/bookmark.svg" alt=">" onClick={handleBookmarkClick} />
          </PostImageContainer>
          <PostStats>
            <PostDate>{new Date(post.taken_at * 1000).toLocaleDateString()}</PostDate>
            <PostStatsItem>{post.like_count} Likes</PostStatsItem>
            <PostStatsItem>{post.comment_count} Comments</PostStatsItem>
          </PostStats>
          <PostDetails>
            <PostCaptionContainer>
              <PostCaption isExpanded={isExpanded}>
                <PostUsername>{post.username}</PostUsername> &ensp; {post.caption}
              </PostCaption>
              {!isExpanded && <MoreButton onClick={toggleCaption}>... more</MoreButton>}
            </PostCaptionContainer>
          </PostDetails>
        </PostLayout>
      </Layout>
      {isLoginModalOpen && <LoginModal handleClosePopup={() => setIsLoginModalOpen(false)} handleSignIn={handleSignIn} />}
    </>
  );
}

export default FullScreenInstagramPost;
