import styled from "styled-components";
import { addBookMark } from "../apis/bookmark";
import useBookmarks from "../hooks/useBookMarks";
import { isEqual } from "../apis/common";
import { useEffect, useState, useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoginModalOpenState, userMeState } from "../stores/authStore";
import _ from "lodash";

const BookmarkImage = styled.div`
  display: flex;
  cursor: pointer;
`;

export const BookMarkButton = ({ style, bookmark }) => {
  const { bookmarks } = useBookmarks();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(isLoginModalOpenState);
  const userMe = useRecoilValue(userMeState);

  useEffect(() => {
    setIsBookmarked(bookmarks.find((item) => isEqual(item, bookmark)));
  }, [bookmarks]);

  const handleClickBookMarkImage = useCallback(
    (e) => {
      e.stopPropagation();
      // 로그인이 안된 상태로 북마크를 하지 못하도록 로그인창을 띄움
      if (_.isEmpty(userMe)) {
        return setIsLoginModalOpen(true);
      }
      addBookMark(bookmark);
    },
    [bookmark, userMe]
  );

  const iconPath = isBookmarked ? "/svg/bookmark-filled.svg" : "/svg/bookmark.svg";

  return (
    <BookmarkImage style={style} onClick={handleClickBookMarkImage}>
      <img src={iconPath} alt=">" />
    </BookmarkImage>
  );
};
