import styled from 'styled-components';
import { addBookMark } from '../apis/bookmark';
import useBookmarks from '../hooks/useBookMarks';
import { isEqual } from '../apis/common';
import { useEffect, useState, useCallback } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoginModalOpenState, userMeState } from "../stores/authStore";
import _ from "lodash";

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
`;

const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; // This adds the 8px gap between the text and the SVG
`;

const TitleText = styled.div`
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
`

const BookmarkImage = styled.div`
    display: flex;
    cursor: pointer;
`

const handleAddBookMark = async (bookmark) => {
    return await addBookMark(bookmark);
}

function TitleWithBookMark({ title, bookmark }) {
    const { bookmarks } = useBookmarks();
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(isLoginModalOpenState);
    const userMe = useRecoilValue(userMeState);

  
    useEffect(() => {
      setIsBookmarked(bookmarks.find((item) => isEqual(item, bookmark)));
    }, [bookmarks]);
  
    const handleClickBookMarkImage = useCallback(
      (e) => {
        e.stopPropagation();
        // 로그인이 안된 상태로 북마크를 하지 못하도록 로그인창을 띄움
        if (_.isEmpty(userMe)) {
          return setIsLoginModalOpen(true);
        }
        addBookMark(bookmark);
      },
      [bookmark, userMe]
    );
  
    const iconPath = isBookmarked ? "/svg/bookmark-filled.svg" : "/svg/bookmark.svg";

    return (
        <TitleContainer>
            <TitleWithIcon>
                <TitleText>{title}</TitleText>
                {title === 'Review' && <img src="/svg/stars.svg" alt="Stars icon" />}
            </TitleWithIcon>
            <BookmarkImage onClick={handleClickBookMarkImage}>
                <img src={iconPath} alt=">" />
            </BookmarkImage>
        </TitleContainer>
    );
}

export default TitleWithBookMark;