import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, deleteDoc, getDocs, updateDoc, query, where } from 'firebase/firestore';

async function postSearchHistory(searchTerm) {
    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;

    if (user) {
        // Firestore에 검색 이력 추가 또는 업데이트
        const q = query(collection(db, "searchHistory"), where("uid", "==", user.uid), where("searchTerm", "==", searchTerm));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            // 새 검색 이력 추가
            await addDoc(collection(db, "searchHistory"), {
                uid: user.uid,
                searchTerm: searchTerm,
                timestamp: new Date()
            });
        } else {
            // 기존 검색 이력의 타임스탬프 업데이트
            querySnapshot.forEach(async (doc) => {
                await updateDoc(doc.ref, {
                    timestamp: new Date()
                });
            });
        }
    } else {
        // 쿠키에 검색 이력 추가 또는 업데이트
        let cookie = document.cookie.split("; ").find(row => row.startsWith("searchHistory="));
        let searchHistory = cookie ? JSON.parse(cookie.split("=")[1]) : [];
        let existingEntry = searchHistory.find(item => item.searchTerm === searchTerm);
        if (existingEntry) {
            // 기존 검색 이력의 타임스탬프 업데이트
            existingEntry.timestamp = new Date();
        } else {
            // 새 검색 이력 추가
            searchHistory.push({ searchTerm: searchTerm, timestamp: new Date() });
        }
        document.cookie = "searchHistory=" + JSON.stringify(searchHistory);
    }
}

// 사용자의 검색 이력을 가져오는 함수
async function getSearchHistory() {
    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;
    let searchHistory = [];

    if (user) {
        // Firestore에서 사용자의 검색 이력 가져오기
        const q = query(collection(db, "searchHistory"), where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            searchHistory.push(doc.data());
        });
    } else {
        // 쿠키에서 사용자의 검색 이력 가져오기
        let cookie = document.cookie.split("; ").find(row => row.startsWith("searchHistory="));
        if (cookie) {
            searchHistory = JSON.parse(cookie.split("=")[1]);
        }
    }

    return searchHistory;
}

// 특정 검색 이력을 삭제하는 함수
async function deleteSearchHistory(searchTerm) {
    const auth = getAuth();
    const db = getFirestore();
    const user = auth.currentUser;

    if (user) {
        // Firestore에서 해당 검색 이력 삭제
        const q = query(collection(db, "searchHistory"), where("uid", "==", user.uid), where("searchTerm", "==", searchTerm));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
            await deleteDoc(doc.ref);
        });
    } else {
        // 쿠키에서 해당 검색 이력 삭제
        let cookie = document.cookie.split("; ").find(row => row.startsWith("searchHistory="));
        let searchHistory = cookie ? JSON.parse(cookie.split("=")[1]) : [];
        searchHistory = searchHistory.filter(item => item.searchTerm !== searchTerm);
        document.cookie = "searchHistory=" + JSON.stringify(searchHistory);
    }
}

export { postSearchHistory, getSearchHistory, deleteSearchHistory };