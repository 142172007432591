import { getAuth } from "firebase/auth";
import { getFirestore, collection, doc, addDoc, getDocs, query, where, deleteDoc, endAt, orderBy } from "firebase/firestore";
import { isEqual } from "./common";
import { incrementCount } from "./analytics";

// bookmark 객체를 인자로 받는 함수
async function addBookMark(bookmark) {
  const auth = getAuth();
  const db = getFirestore();

  const user = auth.currentUser;
  if (user) {
    const q = query(collection(db, "bookmarks"), where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);
    let isDuplicate = false;

    querySnapshot.forEach((doc) => {
      const existingBookmark = doc.data();
      const newBookmark = { ...bookmark, uid: user.uid };
      if (isEqual(existingBookmark, newBookmark)) {
        isDuplicate = true;
      }
    });

    if (isDuplicate) {
      alert("이미 존재하는 북마크입니다");
    } else {
      await addDoc(collection(db, "bookmarks"), {
        ...bookmark,
        uid: user.uid,
        createdAt: new Date(), // 현재 날짜와 시간을 추가
      });
      alert("북마크가 성공적으로 추가되었습니다");

      await incrementCount("BookmarkCount");
    }
  } else {
    let cookie = document.cookie.split("; ").find((row) => row.startsWith("bookmarks="));
    let bookmarks = cookie ? JSON.parse(cookie.split("=")[1]) : [];
    let isDuplicate = bookmarks.some((item) => isEqual(item, bookmark));

    if (isDuplicate) {
      alert("이미 존재하는 북마크입니다");
    } else {
      bookmarks.push(bookmark);
      if (bookmarks.length > 5) {
        bookmarks.shift();
      }
      document.cookie = "bookmarks=" + JSON.stringify(bookmarks);
      alert("북마크가 성공적으로 추가되었습니다");

      await incrementCount("BookmarkCount");
    }
  }
}

// uid를 인자로 받는 함수
async function getBookMarks() {
  const auth = getAuth();
  const db = getFirestore();
  const uid = auth.currentUser ? auth.currentUser.uid : null;
  let bookmarks = [];

  if (uid) {
    // 'createdAt' 필드를 기준으로 내림차순 정렬
    const q = query(collection(db, "bookmarks"), where("uid", "==", uid), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      bookmarks.push(doc.data());
    });
  } else {
    let cookie = document.cookie.split("; ").find((row) => row.startsWith("bookmarks="));
    if (cookie) {
      cookie = JSON.parse(cookie.split("=")[1]);
      // 쿠키에 저장된 북마크는 추가 순서대로 정렬되어 있을 것으로 가정
      bookmarks.push(...cookie);
    }
  }

  return bookmarks;
}

// bookmark 객체를 인자로 받는 함수
function goBookMarkPath(bookmark) {
  // bookmark의 type, query, isSeeMore, verticalScrollItemId, horizontalScrollItemId, shortsId, youtubeId를 구조 분해 할당합니다.
  const { type, query, isSeeMore, horizontalScrollItemId, verticalScrollItemId, shortsId, youtubeId, instagramId } = bookmark;
  // path 변수를 선언합니다.
  let path = "";
  let baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://www.fordigger.com";
  // type에 따라 path를 결정합니다.
  switch (type) {
    case "homepage":
      path = `${baseURL}/all?q=${query}&selectedTab=all`;
      break;
    case "shorts":
      if (isSeeMore) {
        if (shortsId) {
          path = `${baseURL}/shorts/${shortsId}?q=${query}&selectedTab=shorts`;
        } else {
          path = `${baseURL}/shorts?q=${query}&selectedTab=shorts`;
        }
      } else {
        path = `${baseURL}/all?q=${query}&selectedTab=shorts&horizontalScrollItemId=${horizontalScrollItemId}`;
      }
      break;
    case "youtube":
      if (isSeeMore) {
        if (youtubeId) {
          path = `${baseURL}/youtube/${youtubeId}?q=${query}&selectedTab=youtube`;
        } else {
          path = `${baseURL}/youtube?q=${query}&selectedTab=youtube&verticalScrollItemId=${verticalScrollItemId}`;
        }
      } else {
        path = `${baseURL}/all?q=${query}&selectedTab=youtube&horizontalScrollItemId=${horizontalScrollItemId}`;
      }
      break;
    case "price":
      path = `${baseURL}/all?q=${query}&selectedTab=price`;
      break;
    case "review":
      if (isSeeMore) {
        path = `${baseURL}/review?q=${query}&selectedTab=review`;
      } else {
        path = `${baseURL}/all?q=${query}&selectedTab=review`;
      }
      break;
    case "instagram":
      if (isSeeMore) {
        if (instagramId) {
          path = `${baseURL}/instagram/${instagramId}?q=${query}&selectedTab=instagram`;
        } else {
          path = `${baseURL}/instagram?q=${query}&selectedTab=instagram`;
        }
      } else {
        path = `${baseURL}/all?q=${query}&selectedTab=instagram&horizontalScrollItemId=${horizontalScrollItemId}`;
      }
      break;
    case "community":
      path = `${baseURL}/all?q=${query}&selectedTab=community`;
      break;
    default:
      path = "Invalid type";
  }
  // path를 반환합니다.
  return path;
}

// bookmark 객체를 인자로 받는 함수
async function deleteBookMark(bookmark) {
  const auth = getAuth();
  const db = getFirestore();
  const user = auth.currentUser;

  if (user) {
    // Firebase에서 북마크 삭제 (기존 로직 유지)
    const q = query(collection(db, "bookmarks"), where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (isEqual(doc.data(), bookmark)) {
        deleteDoc(doc.ref);
      }
    });
  } else {
    // 쿠키에서 북마크 삭제
    let cookie = document.cookie.split("; ").find((row) => row.startsWith("bookmarks="));
    if (cookie) {
      let bookmarks = JSON.parse(cookie.split("=")[1]);
      bookmarks = bookmarks.filter((item) => !isEqual(item, bookmark)); // 특정 북마크만 제거
      document.cookie = "bookmarks=" + JSON.stringify(bookmarks); // 쿠키 업데이트
    }
  }
}

export { addBookMark, getBookMarks, deleteBookMark, goBookMarkPath };
