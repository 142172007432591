import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, getFirestore, onSnapshot, query, where } from 'firebase/firestore';

function useSearchHistory() {
    const [searchHistory, setSearchHistory] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();

        onAuthStateChanged(auth, (user) => {
            if (user) {
                const q = query(collection(db, "searchHistory"), where("uid", "==", user.uid));
                // 여기서 onSnapshot을 사용합니다.
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const history = querySnapshot.docs.map(doc => doc.data());
                    setSearchHistory(history);
                    setLoading(false);
                });

                // useEffect 정리 함수에서 구독을 취소합니다.
                return () => unsubscribe();
            } else {
                const history = getSearchHistoryFromCookie();
                setSearchHistory(history);
                setLoading(false);
            }
        });
    }, []);

    return { searchHistory, loading };
}

function getSearchHistoryFromCookie(){
    let cookie = document.cookie.split("; ").find(row => row.startsWith("searchHistory="));
    return cookie ? JSON.parse(cookie.split("=")[1]) : [];
}

export default useSearchHistory;