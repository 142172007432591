import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getYoutubeShortsList } from '../apis/shorts';
import FixedTabBar from './FixedTabBar';
import { FixedBookMarkButton } from './FixedBookMarkButton';

const VideoContainer = styled.div`
    position: relative;
    height: ${props => props.viewportHeight}px;  // modified line
    background: black;
    display: flex;
    align-items: center;
`;

const VideoIframe = styled.iframe`
    width: 100%;
    height: 205px;

    @media (min-width: 768px) {
      height: 100%;
    }
`;

const FullScreenVideo = () => {
  const { id } = useParams();
  const urlSearch = new URLSearchParams(window.location.search);
  const [searchQuery, setSearchQuery] = useState(urlSearch.get('q') || '');
  const bookmark = {
    type: 'youtube',
    query: searchQuery,
    isSeeMore: true,
    youtubeId: id,
  };
  const navigate = useNavigate();
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleBack = () => {
      navigate(-1);
    };

    window.addEventListener('popstate', handleBack);

    return () => {
      window.removeEventListener('popstate', handleBack);
    };
  }, []);

  return ( 
    <VideoContainer viewportHeight={viewportHeight}>
      {id !== null && <VideoIframe
        src={`https://www.youtube.com/embed/${id}`} // Replace [VIDEO_ID] with your YouTube video ID
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="Video"
        height='205'
      />}
      <FixedTabBar style={{ width: 'calc(100% - 56px)', height: '44px', bottom: '66px' }} />
      <FixedBookMarkButton bookmark={bookmark} />
      {/* <OverlayImage src={'/svg/bookmark.svg'} alt="Overlay" onClick={onTest} /> */}
    </VideoContainer>
  );
};

export default FullScreenVideo;
