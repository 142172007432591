import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

async function getPostFromInstagramCollection(id) {
    const q = query(collection(db, "instagram"), where("instagramId", "==", id));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return null;
    return querySnapshot.docs[0].data();
};

async function savePostToInstagramCollection(post) {
    const q = query(collection(db, "instagram"), where("instagramId", "==", post.id));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
        // If the post does not exist, add it to the collection
        await addDoc(collection(db, "instagram"), post);
    }
}

export { getPostFromInstagramCollection, savePostToInstagramCollection };