import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getInstagramPosts } from "../apis/instagram";
import { LoadingIndicatorComponent } from "./LoadingIndicator";
import { useNavigate } from "react-router-dom";

const Layout = styled.div`
  max-height: 100vh;
  height: calc(100vh - 72px);
`;

const QueryText = styled.div`
  color: var(--label-primary, #000);
  font-size: 14px;
  font-weight: 400;
  padding-top: 12px;
  padding-left: 16px;
  line-height: 16px;
`;

const InstagramSecondary = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--Neutral-neutral2, #838383);
  padding-top: 4px;
  padding-bottom: 12px;
  padding-left: 16px;
`;

const InstagramGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
  column-gap: 8px;
  row-gap: 8px;
  padding: 0 8px; // 좌우 패딩 추가
  flex: 1;
  padding-bottom: 100px;
`;

const InstagramItem = styled.div`
  flex: 1 1 calc(50% - 8px); // 2열 레이아웃
  cursor: pointer; // 마우스 커서 변경
  max-width: calc((100% - 8px) / 2); // 최대 너비 제한
  height: calc(50vw - 8px); // 높이를 너비와 동일하게 조정
  text-align: center;

  @media (min-width: 768px) {
    max-width: calc((100% - 24px) / 4);
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%; // 이미지 높이를 부모 요소와 동일하게 조정
  object-fit: cover; // 이미지 비율 유지
  border-radius: 12px;
`;

// InstagramList 컴포넌트에서 window의 스크롤 이벤트를 사용합니다.
function InstagramList() {
  const [searchQuery, setSearchQuery] = useState(new URLSearchParams(window.location.search).get("q") || "");
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    async function run() {
      const newItems = await getInstagramPosts(searchQuery, page);
      if (newItems && newItems.length > 0) {
        setItems((prevItems) => [...prevItems, ...newItems]);
      }
      setIsLoading(false);
    }

    run();
  }, [searchQuery, page]);

  // window의 스크롤 이벤트 핸들러를 정의합니다.
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // window의 스크롤 이벤트를 등록하고 해제하는 효과를 만듭니다.
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleItemClick = (item) => {
    navigate(`/instagram/${item.id}?q=${searchQuery}&selectedTab=instagram`, {
      state: {
        post: item,
      },
    });
  };

  return (
    <Layout>
      <QueryText>#{searchQuery}</QueryText>
      <InstagramSecondary>
        <div>{items.length} Posts</div>
      </InstagramSecondary>
      <InstagramGrid>
        {items.map((item, index) => (
          <InstagramItem
            key={item.id || index}
            onClick={() => {
              handleItemClick(item);
            }}
          >
            <StyledImage src={item.thumbnail_url} alt="thumbnail" />
          </InstagramItem>
        ))}
        {isLoading && <LoadingIndicatorComponent />}
      </InstagramGrid>
    </Layout>
  );
}

export default InstagramList;
