import getGoogleWebsite from "../apis/google";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Padding } from "./Padding";
import TitleWithBookMark from "./TitleWithBookMark";

const Layout = styled.div`
  // height: 136px;
`;

const SearchResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px;
  //   border: 1px solid #ccc; /* Adjust styling as needed */
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 0px 16px 20px;
  }
`;

const SearchResultHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Favicon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
`;

const Url = styled.span`
  font-size: 12px;
  color: var(--Main-deepBlue, #020c19);
  overflow: hidden;
`;

const TitleText = styled.div`
  font-size: 16px;
  color: #0057ff;
  padding-top: 12px;
`;

const Snippet = styled.div`
  font-size: 12px;
  padding-top: 8px;
  color: #4d4d4d;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const SearchResult = ({ favicon, title, url, snippet }) => {
  return (
    <SearchResultContainer>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <SearchResultHeader>
          <Favicon src={favicon} alt="favicon" />
          <Url>{url}</Url>
        </SearchResultHeader>
        <TitleText>{title}</TitleText>
        <Snippet>{snippet}</Snippet>
      </a>
    </SearchResultContainer>
  );
};

function Homepage() {
  const urlSearch = new URLSearchParams(window.location.search);
  const [searchQuery, setSearchQuery] = useState(urlSearch.get("q") || "");
  const [searchResult, setSearchResult] = useState({
    title: "",
    url: "",
    snippet: "",
    pagemap: {
      cse_thumbnail: [
        {
          src: "",
        },
      ],
    },
  });

  const [bookmark, setBookmark] = useState({
    type: "homepage",
    query: searchQuery,
  });

  useEffect(() => {
    async function run() {
      const googleSearch = await getGoogleWebsite(searchQuery);
      setSearchResult(googleSearch);
    }
    run();
  }, []);

  return (
    <Layout id="all">
      <TitleWithBookMark title={"Homepage"} bookmark={bookmark} />

      <SearchResult
        favicon={
          searchResult?.pagemap?.cse_thumbnail
            ? searchResult?.pagemap?.cse_thumbnail?.[0].src
            : ""
        }
        title={searchResult?.title}
        url={searchResult?.link}
        snippet={searchResult?.snippet}
      />
      <Padding />
    </Layout>
  );
}

export default Homepage;
