import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { auth } from "../firebase";
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { addUser, incrementCount } from "../apis/analytics";
import LoginModal from "./LoginModal";

const SearchBarLayout = styled.div`
  border-bottom: 1px solid var(--Main-deepPurple, #6800ec);
  padding: 16px;
`;

const SearchBarContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  max-height: 40px;
  min-height: 40px;
`;

const SearchBarBox = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
`

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--Neutral-neutral5, #f2f2f2);
  border-radius: 10px;
  width: 100%;
  padding: 10px 16px;
  input::placeholder {
    color: #020c19; // default placeholder color
  }
  input::-webkit-input-placeholder {
    color: #020c19; // default placeholder color for WebKit browsers
  }
  input:-ms-input-placeholder {
    color: #020c19; // default placeholder color for Internet Explorer
  }

  input:focus::placeholder {
    color: grey; // placeholder color when focused
  }
  input:focus::-webkit-input-placeholder {
    color: grey; // placeholder color when focused for WebKit browsers
  }
  input:focus:-ms-input-placeholder {
    color: grey; // placeholder color when focused for Internet Explorer
  }
`;

const SearchIcon = styled.img`
  margin-right: 8px;
`;

const SearchInput = styled.input`
  border: none;
  background: transparent;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 0;
`;

const UserIcon = styled.img`
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  /* ... your styles for the user icon ... */
`;

const DefaultIcon = styled.img`
  /* ... your styles for the default icon, possibly a styled grey circle ... */
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResultSearchBar = ({ activeTab }) => {
  const [user, setUser] = useState(null);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const placeholder = searchParams.get("q");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      addUser(result.user.uid);
      setUser(result.user);
      setShowBottomSheet(false);
      localStorage.setItem("user", result.user.uid);
    } catch (error) {
      console.error(error);
    }
  };

  const handleIconClick = () => {
    if (!user) {
      setShowBottomSheet(true);
    }
    // If the user is logged in, you might want to do something else
  };


  const handleCloseBottomSheet = () => {
    setShowBottomSheet(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const handleChangeUser = async () => {
    const checkLogout = window.confirm("로그아웃 하시겠습니까?");
    if (checkLogout) await signOut(auth);
    localStorage.setItem("user", null);
    // const provider = new GoogleAuthProvider();
    // try {
    //   const result = await signInWithPopup(auth, provider);
    //   setUser(result.user);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleSearchKeyPress = async (event) => {
    if (event.key === "Enter") {
      window.location.href = `/all?q=${encodeURIComponent(searchQuery)}&selectedTab=${activeTab}`;

      await incrementCount("SearchCount");
    }
  };

  return (
    <SearchBarLayout>
      <SearchBarContainer>
        <img src="/svg/Vector.svg" alt="Search" onClick={handleGoHome} />
        <SearchInputContainer>
          <SearchIcon src="/svg/discover.svg" alt="Search" />
          <SearchInput placeholder={placeholder} value={searchQuery} onChange={handleSearchChange} onKeyPress={handleSearchKeyPress} />
        </SearchInputContainer>
        {user ? (
          <UserIcon src={user.photoURL} alt="User" onClick={handleChangeUser} />
        ) : (
          <div style={{ background: "#B3B3B3", borderRadius: "50%" }}>
            <DefaultIcon src="/svg/person.svg" onClick={handleIconClick}></DefaultIcon>
          </div>
        )}
      </SearchBarContainer>

      {showBottomSheet && <LoginModal handleClosePopup={handleCloseBottomSheet} handleSignIn={handleSignIn} />}
    </SearchBarLayout>
  );
};

export default ResultSearchBar;
