import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const TabsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  border-bottom: 1px solid var(--Neutral-neutral5, #F2F2F2);
  height: 48px;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

const ChipBarContainer = styled(TabsContainer)`
  padding: 12px 16px;
  border-bottom: 1px solid var(--Neutral-neutral5, #F2F2F2);
  background-color: var(--Main-white, #FFF); /* Adjust the background color as needed */
  height: 100%;
`;

const ChipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px; // This adds the 4px gap between the SVG and the text
`;

const Chip = styled.button`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
//   flex: 1;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 24px;
  border: none;
  background: ${(props) => (props.isSelected ? '#6800EC' : 'transparent')};
  color: ${(props) => (props.isSelected ? 'white' : '#020C19')};
//   font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  outline: none;
  position: relative; /* Added for positioning the pseudo-element */
  border:  ${(props) => (props.isSelected ? "" : "1px solid var(--Neutral-neutral4, #dcdcdc)")};
  margin-right: 8px;
  height: 36px;
`;

const Tab = styled.div`
  display: inline-flex; /* Changed from flex to inline-flex */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  cursor: pointer;
  border: none;
  position: relative; /* Added for positioning the pseudo-element */
  display: flex;
  flex: 1;

  div {
    font-size: 14px;
    height: 100%;
    font-weight: ${(props) => (props.isSelected ? '600' : 'normal')};
    border-bottom: ${(props) => (props.isSelected ? '2px solid #020C19' : 'none')};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0; /* Align to the bottom of the Tab */
    left: 50%; /* Center the line */
    transform: translateX(-50%); /* Center the line with transform */
    width: 0; /* Initial width to 0 */
    height: 2px; /* Height of the underline */
    background: var(--Your-Color-Variable, #6800EC);
    border-radius: 1px; /* Optional: if you want rounded corners on the underline */
    transition: width 0.3s; /* Smooth transition for the line */
  }

  &.selected::after {
    width: auto; /* 선택된 탭에 대해 자동 너비 설정 */
  }
`;

const TabText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 0; /* Align to the bottom of the Tab */
    left: 50%; /* Center the line */
    transform: translateX(-50%); /* Center the line with transform */
    width: 0; /* Initial width to 0 */
    height: 2px; /* Height of the underline */
    background: var(--Your-Color-Variable, #6800EC);
    border-radius: 1px; /* Optional: if you want rounded corners on the underline */
    transition: width 0.3s; /* Smooth transition for the line */
  }
`;

const mainTabs = [
    { name: 'Home', query: 'all' },
    { name: 'Price Comparison', query: 'price' },
    { name: 'Community', query: 'community' },
];

const homeChipTabs = [
    { name: 'All', query: 'all' },
    { name: 'Shorts', query: 'shorts' },
    { name: 'Youtube', query: 'youtube' },
    { name: 'Review', query: 'review' },
    { name: 'Instagram', query: 'instagram' },
];

function ScrollTabs({ activeTab, setActiveTab }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '');
    const [selectedChipTab, setSelectedChipTab] = useState(activeTab);
    const [isHome, setIsHome] = useState(!(activeTab === 'price' || activeTab === 'community'));

    useEffect(() => {
        const query = searchParams.get('selectedTab');

        // 수정 필요
        setTimeout(() => {
            scrollToElement(query);
        }, 1000);
    }, [])

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            if (elementId !== 'price' && elementId !== 'community') {
                // For non 'price' and 'community' tabs, scroll 180px below the element
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({ top: elementPosition - 180, behavior: 'smooth' });
            } else {
                // For 'price' and 'community' tabs, use the default scrolling behavior
                element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
            }
        }
    };

    useEffect(() => {
        // Ensure that the active tab is highlighted and set isHome correctly when the component loads.
        setSelectedChipTab(activeTab);
        setIsHome(!(activeTab === 'price' || activeTab === 'community'));
    }, [activeTab]);

    const handleMainTabClick = (tabQuery) => {
        setActiveTab(tabQuery); // Update the active tab using the handler from parent
        setSearchParams({ selectedTab: tabQuery, q: searchQuery }); // Update URL search params
    };

    const handleChipTabClick = (tabQuery) => {
        setActiveTab(tabQuery); // Set active tab for chips using the handler from parent
        setSelectedChipTab(tabQuery); // Update the selected chip tab state
        setSearchParams({ selectedTab: tabQuery, q: searchQuery }); // Update URL search params
        scrollToElement(`${tabQuery}`); // Scroll to the selected chip
    };

    return (
        <>
            <TabsContainer>
                {mainTabs.map((tab) => (
                    <Tab
                        key={tab.query}
                        isSelected={activeTab === tab.query || (isHome && tab.query === 'all')}
                        onClick={() => handleMainTabClick(tab.query)}
                    >
                        <TabText>{tab.name}</TabText>
                    </Tab>
                ))}
            </TabsContainer>
            {isHome && (
                <ChipBarContainer>
                    {homeChipTabs.map((tab, index) => (
                        <Chip
                            id={`chip-${tab.query}`}
                            key={tab.query}
                            isSelected={selectedChipTab === tab.query}
                            onClick={() => handleChipTabClick(tab.query)}
                        >
                            <ChipContent>
                                {tab.query === 'all' ? <></> : tab.query === 'review' ? <img src={`/svg/naver.svg`} alt={`${tab.name} icon`} /> : (selectedChipTab === "instagram" && tab.query === "instagram") ? <img src={`/svg/selectedInstagram.svg`} alt={`${tab.name} icon`} /> : <img src={`/svg/${tab.query}.svg`} alt={`${tab.name} icon`} />} 
                                {tab.name}
                            </ChipContent>
                        </Chip>
                    ))}
                </ChipBarContainer>
            )}
        </>
    );
}

export default ScrollTabs;