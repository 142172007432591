import { atom } from "recoil";

export const isLoginModalOpenState = atom({
  key: "isLoginModalOpen",
  default: false,
});

export const userMeState = atom({
  key: "userMe",
  default: {},
});
