import Title from "./Title";
import { useEffect, useState } from "react";
import getDanawaWebsite from "../apis/danawa";
import { Padding } from "./Padding";
import TitleWithBookMark from "./TitleWithBookMark";
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoginModalOpenState, userMeState } from "../stores/authStore";
import { auth } from '../firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import _ from "lodash";
import LoginModal from "./LoginModal";
import { addUser } from "../apis/analytics";

const PriceWrapper = styled.div`
    iframe{
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        height: calc(100vh - 162px);
    }
`;

function PriceComparison() {
    const urlSearch = new URLSearchParams(window.location.search);
    const [searchQuery, setSearchQuery] = useState(urlSearch.get('q') || '');
    const [searchResult, setSearchResult] = useState('');
    const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(isLoginModalOpenState);
    const userMe = useRecoilValue(userMeState);
    const [bookmark, setBookmark] = useState({
        type: 'price',
        query: searchQuery,
    });

    useEffect(() => {
        async function run() {
            const danawaSearch = getDanawaWebsite(searchQuery);
            setSearchResult(danawaSearch);
        }

        run();
    }, []);

    const handleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            addUser(result.user.uid);
            localStorage.setItem("user", result.user.uid);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <PriceWrapper id="price">
                <TitleWithBookMark title={"Price Comparison"} bookmark={bookmark} />
                <iframe src={searchResult} width="100%" height="500px"></iframe>
                <Padding />
            </PriceWrapper>
            {isLoginModalOpen && <LoginModal handleClosePopup={() => setIsLoginModalOpen(false)} handleSignIn={handleSignIn} />}
        </>
    )
}

export default PriceComparison;