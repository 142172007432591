import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { getYoutubeVideos, getYoutubeVideosList } from "../apis/youtube";
import YouTube from "react-youtube";
import Title from "./Title";
import SeeMoreButton from "./SeeMoreButton";
import { Padding } from "./Padding";
import { BookMarkButton } from "./BookMarkButton";
import { useNavigate } from "react-router-dom";

const YoutubeContainer = styled.div`
  background-color: var(--Neutral-neutral5, #f2f2f2);
`;

const YoutubeItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 350px;
`;

const YoutubeInformation = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const YoutubeChannelTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: var(--Main-deepBlue, #020c19);
`;

const YoutubeTitle = styled.span`
  color: var(--Main-deepBlue, #020c19);
  font-size: 14px;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding-right: 20px;

  iframe {
    padding-left: 0px;
    padding-right: 0px;
    width: 350px;
  }
`;

function YoutubeVideos() {
  const navigate = useNavigate();
  const urlSearch = new URLSearchParams(window.location.search);
  const [searchQuery, setSearchQuery] = useState(urlSearch.get("q") || "");
  const [searchResult, setSearchResult] = useState({
    items: [
      {
        id: "",
      },
    ],
  });
  const scrollContainerRef = useRef(null); // Create a ref for the ScrollContainer
  const horizontalScrollItemId = parseInt(urlSearch.get("horizontalScrollItemId"), 10);
  const bookmark = {
    type: "youtube",
    query: searchQuery,
    isSeeMore: false,
  };

  const opts = {
    height: "192px",
    width: "350px",
  };

  useEffect(() => {
    async function run() {
      const videosSearch = await getYoutubeVideosList(searchQuery);
      setSearchResult(videosSearch);
    }

    run();
  }, []);

  useEffect(() => {
    // Scroll to the item when the component mounts or horizontalScrollItemId changes
    if (scrollContainerRef.current && !isNaN(horizontalScrollItemId) && urlSearch.get("selectedTab") === "youtube") {
      const targetElement = scrollContainerRef.current.children[horizontalScrollItemId];
      if (targetElement) {
        scrollContainerRef.current.scrollLeft = targetElement.offsetLeft - 16; // Subtracting padding if any
      }
    }
  }, [horizontalScrollItemId, searchResult]);

  const onClickYoutube = (id) => {
    navigate(`/youtube/${id}?q=${searchQuery}&selectedTab=youtube`);
  };

  return (
    <YoutubeContainer id="youtube">
      <Title title={"Youtube"} />
      <ScrollContainer ref={scrollContainerRef}>
        {searchResult &&
          searchResult.items.slice(0, 8).map((item, index) => {
            return (
              <React.Fragment key={index}>
                <YoutubeItem
                  onClick={() => {
                    onClickYoutube(item.id);
                  }}
                >
                  <BookMarkButton style={{ position: "absolute", top: "12px", right: "12px" }} bookmark={{ ...bookmark, horizontalScrollItemId: index }} />
                  <YouTube videoId={item.id} opts={opts} />
                  <YoutubeInformation style={index === 0 ? { paddingLeft: "16px" } : {}}>
                    <YoutubeChannelTitle>{item.channelTitle}</YoutubeChannelTitle> &ensp;
                    <YoutubeTitle>{item.title}</YoutubeTitle>
                  </YoutubeInformation>
                </YoutubeItem>
              </React.Fragment>
            );
          })}
      </ScrollContainer>
      <SeeMoreButton path="youtube" />
      <Padding />
    </YoutubeContainer>
  );
}

export default YoutubeVideos;
