import React, { useState } from "react";
import styled from "styled-components";

// Styled component for the container of the tabs
const TabsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  padding: 8px 12px;
  height: 169px;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

// Styled component for individual tab items
const Tab = styled.div`
  display: inline-block;
  padding: 8px 12px;
  height: fit-content;
  cursor: pointer;
  border-radius: 24px;
  border: 1px solid var(--Neutral-neutral4, #dcdcdc);
  font-size: 12px;
  background: ${(props) =>
    props.isActive ? "#6800EC" : "var(--Main-white, #FFF)"};
  color: ${(props) => (props.isActive ? "white" : "initial")};
  &:not(:last-child) {
    margin-right: 8px;
  }
  /* You can add :hover, :active, or other pseudo-classes as needed */
`;

const TabBar = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <TabsContainer>
      {tabs.map((tab, index) => (
        <Tab key={index} isActive={tab === activeTab} onClick={() => setActiveTab(tab)}>
          {tab}
        </Tab>
      ))}
    </TabsContainer>
  );
};

// Usage example
const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return <TabBar tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />;
};

export default Tabs;
