import axios from 'axios';
//백엔드로 붙이기

async function getNaverShoppingReviews(query){
    const response = await axios(`/api/v1/naver-products?query=${query}`);
    return response;
}

async function getNaverShoppingReviewPage(query){
    const response = await axios(`/api/v1/naver-shopping-review-page?id=${query}`);
    return response;
}

export { getNaverShoppingReviews, getNaverShoppingReviewPage };