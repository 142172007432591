import axios from "axios";

let max_id = null;
let fetchedPosts = [];
const postsPerPage = 6;
let previousTag = null;

async function getInstagramPosts(tag, page = 1) {
  if (tag !== previousTag) {
    fetchedPosts = [];
    max_id = null;
    previousTag = tag;
  }

  const startIndex = (page - 1) * postsPerPage;

  if (startIndex >= fetchedPosts.length) {
    const response = await axios(`/api/v1/instagram-posts?query=${tag}&max_id=${max_id}`);
    const data = response.data;
    if (data === null) return null;

    const items = data.data.medias;
    max_id = data.data.next_max_id;
    fetchedPosts = fetchedPosts.concat(items);
  }

  const currentPagePosts = fetchedPosts.slice(startIndex, startIndex + postsPerPage);

  const images = await Promise.all(
    currentPagePosts.map((item) => {
      const type = item.product_type;
      const imageURL = type === "carousel_container" ? item.carousel_media[0].image_versions2.candidates[0].url : item.image_versions2.candidates[0].url;
      return axios.post("/api/v1/instagram-images", { imageURL: imageURL });
    })
  );

  const posts = currentPagePosts.map((item, index) => {
    const post = {
      id: item.id,
      username: item.user.username,
      thumbnail_url: images[index].data.base64Image,
      caption: item.caption?.text,
      comment_count: item.comment_count,
      like_count: item.like_count,
      taken_at: item.taken_at,
      // max_id: max_id,
    };
    return post;
  });

  const sortedPosts = posts.sort((a, b) => {
    return b.like_count - a.like_count;
  });

  return sortedPosts;
}

export { getInstagramPosts };
