import React, { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getYoutubeShortsList } from '../apis/shorts';
import FixedTabBar from './FixedTabBar';
import { FixedBookMarkButton } from './FixedBookMarkButton';

const VideoContainer = styled.div`
    position: relative;
    height: ${props => props.viewportHeight}px;
    overflow: hidden;
`;

const StyledYoutube = styled(YouTube)`
    width: 100%;
    height: 100%;

    iframe{
        width: 100%;
        height: 100%;
    }
`;

const SwipeContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    height: 80%;
    width: 100%;
`

const FullScreenShort = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const urlSearch = new URLSearchParams(window.location.search);
    const [searchQuery, setSearchQuery] = useState(urlSearch.get('q') || '');
    const [list, setList] = useState([]);
    const [currentId, setCurrentId] = useState(id);
    const [nextId, setNextId] = useState(null);
    const [prevId, setPrevId] = useState(null);
    const [startY, setStartY] = useState(0);
    const [endY, setEndY] = useState(0);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [showSvg, setShowSvg] = useState(false);
    const [playing, setPlaying] = useState(false); // State to manage video play state
    const videoRef = useRef(null); 
    const bookmark = {
        type: 'shorts',
        query: searchQuery,
        isSeeMore: true,
        shortsId: id,
    };

    useEffect(() => {
        const updateViewportHeight = () => {
            setViewportHeight(window.innerHeight);
        };

        // 컴포넌트가 마운트될 때 viewportHeight 설정
        updateViewportHeight();

        // 브라우저 창 크기가 변경될 때 viewportHeight 업데이트
        window.addEventListener('resize', updateViewportHeight);

        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', updateViewportHeight);
        };
    }, []);

    useEffect(() => {
        // 컴포넌트가 마운트될 때 SVG 표시
        setShowSvg(true);

        // 3초 후 SVG 숨기기
        const timer = setTimeout(() => {
            setShowSvg(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        async function run() {
            const shortsSearch = await getYoutubeShortsList(searchQuery);
            setList(shortsSearch);
        }

        if (searchQuery) {
            run();
        }
    }, [searchQuery])

    useEffect(() => {
        const handleBack = () => {
            navigate(`/shorts?q=${searchQuery}&selectedTab=shorts`);
        };

        window.addEventListener('popstate', handleBack);

        return () => {
            window.removeEventListener('popstate', handleBack);
        };
    }, []);

    useEffect(() => {
        const currentIndex = list.findIndex(item => item.id === currentId);
        if (currentIndex > -1) {
            setNextId(list[currentIndex + 1]?.id || null);
            setPrevId(list[currentIndex - 1]?.id || null);
        }
    }, [list, currentId]);

    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        setEndY(e.touches[0].clientY);
    };

    const handleTouchEnd = () => {
        if (endY === 0) return;

        const diffY = endY - startY;

        if (diffY > 30 && prevId) {
            navigate(`/shorts/${nextId}?q=${searchQuery}&selectedTab=shorts`);
            setStartY(0);
            setEndY(0);
            setCurrentId(prevId);
        } else if (diffY < -30 && nextId) {
            navigate(`/shorts/${nextId}?q=${searchQuery}&selectedTab=shorts`);
            setStartY(0);
            setEndY(0);
            setCurrentId(nextId);
        }
    };

    // const onVideoStateChange = (event) => {
    //     // When the video is paused (state code 2), reload the video
    //     if (event.data === 2) {
    //         videoRef.current.internalPlayer.pauseVideo(); // Pause the video
    //         videoRef.current.internalPlayer.seekTo(0); // Optional: Seek to start
    //     }
    // };

    const togglePlay = () => {
        // Toggle between play and pause
        if (playing) {
            videoRef.current.internalPlayer.pauseVideo();
        } else {
            videoRef.current.internalPlayer.playVideo();
        }
        setPlaying(!playing);
    };

    const opts = {
        playerVars: {
            autoplay: 1,
            controls: 1,
        },
    };

    return (
        <>
        <VideoContainer style={{ width: '100%', height: `${viewportHeight}px` }}>
            <StyledYoutube
                ref={videoRef}
                videoId={id} // Replace with the video id
                opts={opts}
            />
            <SwipeContainer
                onClick={togglePlay}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd} 
            />
            <FixedTabBar style={{ width: 'calc(100% - 56px)', height: '44px', bottom: '66px' }} />
            <FixedBookMarkButton bookmark={bookmark} />
            {/* <OverlayImage src={'/svg/bookmark.svg'} alt="Overlay" onClick={onTest} /> */}
        </VideoContainer>
        {showSvg && (
            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <img src={'/svg/drag.svg'} alt="Overlay" />
            </div>
        )}
        </>
    );
};

export default FullScreenShort;
