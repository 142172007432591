import axios from "axios";

async function getGoogleWebsite(searchQuery) {
  const apiKey = "AIzaSyCFRuubu31C873DSN-CQJ6J4FjnpkrRXqA"; // 여기에 Google API 키 입력
  const searchEngineId = "e37f10799f6124f97"; // 여기에 검색 엔진 ID 입력
  const url = `https://www.googleapis.com/customsearch/v1?key=${apiKey}&cx=${searchEngineId}&q=${searchQuery}`;

  try {
    const response = await axios.get(url);
    const results = response.data.items;
    if (results && results.length > 0) {
      // 첫 번째 검색 결과의 링크를 출력
      return results[0];
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error during search:", error);
  }
}

export default getGoogleWebsite;
