import React, { useEffect, useState } from "react";
import SearchBar from "../components/SearchBar";
import Tabs from "../components/Tabs";
import styled from "styled-components";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 657px;
  margin: 0 auto;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function Home() {
  const [activeTab, setActiveTab] = useState("All"); // Default active tab
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const tabs = ["All", "Shorts", "Youtube", "Price", "Review", "Instagram", "Community"];

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <HomeContainer style={{ height: `${windowHeight}px` }}>
      <SearchContainer>
        <img src="/svg/mainLogo.svg" alt="logo" style={{ marginBottom: "40px" }} />
        <SearchBar activeTab={activeTab} />
      </SearchContainer>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
    </HomeContainer>
  );
}

export default Home;
