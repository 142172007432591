import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC4K7T5wZm9w7b9S3KtIyYIUXRwFfHfIek",
    authDomain: "diggit-3901b.firebaseapp.com",
    projectId: "diggit-3901b",
    storageBucket: "diggit-3901b.appspot.com",
    messagingSenderId: "776646857419",
    appId: "1:776646857419:web:2a8b45e69fe8fdfe5f414e"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
