import { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { BookMarkButton } from "./BookMarkButton";
import { getInstagramPosts } from "../apis/instagram";
import Title from "./Title";
import SeeMoreButton from "./SeeMoreButton";
import { Padding } from "./Padding";
import { LoadingIndicatorComponent } from "./LoadingIndicator";
import { useNavigate } from "react-router-dom";

const InstagramFeedWrapper = styled.div``;

const InstagramCard = styled.div`
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 12px;
  background: var(--Neutral-neutral5, #F2F2F2);
  gap: 12px;
`;

const ResultHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 235px;
  max-width: 100%;
`;

const TitleText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 8px;
`;

const QueryText = styled.span`
  color: var(--Main-deepPurple, #6800EC);
`;

const InstagramText = styled.span`
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  width: 235px;
  font-size: 14px;
`;

const Caption = styled.span`
  font-weight: normal;
`;

const InstagramFeed = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding-left: 16px;
`;

const ThumbnailImage = styled.img`
  width: 235px;
  height: 277px;
  object-fit: cover;
`;

const PaddingBottom = styled.div`
  height: 120px;
  background: white;
  width: 100%;
`;

function Instagram() {
  const [searchQuery, setSearchQuery] = useState(new URLSearchParams(window.location.search).get('q') || '');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const feedRef = useRef({ isFetching: false });
  const observer = useRef();
  const urlSearch = new URLSearchParams(window.location.search);
  const postsPerPage = 6;
  const horizontalScrollItemId = parseInt(urlSearch.get('horizontalScrollItemId'), 10) || 0;
  const itemWidth = 275; // Width of each InstagramCard
  const bookmark = {
    type: 'instagram',
    query: searchQuery,
    isSeeMore: false,
  };

  const loadMoreItems = async () => {
    const nextPage = page + 1;
    setIsLoading(true);
    const instagramSearch = await getInstagramPosts(searchQuery, nextPage);
    setIsLoading(false);
    if (instagramSearch === null) return nextPage;

    setSearchResult(prev => [...prev, ...instagramSearch]);
    return nextPage;
  };

  const onClickInstagramPost = (post) => {
    navigate(`/instagram/${post.id}?q=${searchQuery}&selectedTab=instagram`, {
      state: {
        post: post,
      }
    });
  }

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const instagramSearch = await getInstagramPosts(searchQuery, page);
      setIsLoading(false);
      if (instagramSearch === null) return;

      setSearchResult(prev => [...prev, ...instagramSearch]);
    };

    run();
  }, [searchQuery, page]);

  useEffect(() => {
    const selectedTab = urlSearch.get('selectedTab');

    if (selectedTab !== 'instagram') return;

    const scrollToItem = async () => {
      let requiredPages = Math.ceil(horizontalScrollItemId / postsPerPage);
      let currentPage = page;

      while (currentPage < requiredPages) {
        const nextPage = await loadMoreItems();
        if (nextPage) {
          currentPage = nextPage;
          setPage(currentPage);
        } else {
          break;
        }
      }

      if (searchResult.length >= horizontalScrollItemId) {
        let scrollPosition = horizontalScrollItemId * itemWidth + 12;
        feedRef.current.scrollLeft = scrollPosition;
      }
    };

    scrollToItem();
  }, [horizontalScrollItemId, page, searchResult.length]);

  const handleScroll = (e) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.target;

    if (scrollLeft + clientWidth >= scrollWidth - 5) {
      if (!feedRef.current.isFetching) {
        feedRef.current.isFetching = true;
        setPage(prev => prev + 1);
        setTimeout(() => {
          feedRef.current.isFetching = false;
        }, 2000);
      }
    }
  };

  const lastElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !feedRef.current.isFetching) {
        setPage(prev => prev + 1);
        feedRef.current.isFetching = true;
        setTimeout(() => {
          feedRef.current.isFetching = false;
        }, 2000);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading]);

  return (
    <InstagramFeedWrapper id="instagram">
      <Title title={"Instagram"} />
      <InstagramFeed onScroll={handleScroll} ref={feedRef}>
        {searchResult.map((post, index) => (
          <InstagramCard key={index} onClick={() => { onClickInstagramPost(post) }}>
            <ResultHeader>
              <TitleText>Result for <QueryText>#{searchQuery.toLowerCase().replace(/\s/g, '')}</QueryText></TitleText>
              <BookMarkButton bookmark={{ ...bookmark, horizontalScrollItemId: index }} />
            </ResultHeader>
            <ThumbnailImage src={post.thumbnail_url} alt="Instagram post" />
            <InstagramText>
              {post.username} &ensp;
              <Caption>{post.caption}</Caption>
            </InstagramText>
          </InstagramCard>
        ))}
        {isLoading && <LoadingIndicatorComponent />}
        <div ref={lastElementRef} />
      </InstagramFeed>
      <SeeMoreButton path="instagram" />
      <PaddingBottom />
    </InstagramFeedWrapper>
  );
}

export default Instagram;
