import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SplitImage = styled.div`
    display: flex;
    cursor: pointer;
    padding-inline: 12px;
`

export const SplitButton = () => {
    const navigate = useNavigate();
    const handleGoSplitView = () => {
        navigate(`/split`);
    }

    return (
            <SplitImage onClick={handleGoSplitView}><img src="/svg/split.svg" alt=">" /></SplitImage>
    );
}