import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { useRecoilState } from "recoil";
import { isLoginModalOpenState, userMeState } from "../stores/authStore";

function useBookmarks() {
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userMe, setUserMe] = useRecoilState(userMeState);
  const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(isLoginModalOpenState);
  //   const cookieBookmarks = useCookie("bookmarks"); // Using the custom hook

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        const q = query(collection(db, "bookmarks"), where("uid", "==", user.uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const bookmarks = querySnapshot.docs.map((doc) => doc.data());
          const data = bookmarks.sort((a, b) => b.createdAt - a.createdAt);
          setBookmarks(data);
          setLoading(false);
          setUserMe({ email: user.email, phoneNumber: user.phoneNumber, displayName: user.displayName, uid: user.uid });
          setIsLoginModalOpen(false);
        });

        return () => unsubscribe();
      } else {
        // const cookieBookmarks = getCookieValue("bookmarks");
        // const reversedBookmarks = cookieBookmarks.reverse();
        setBookmarks([]);
        setUserMe({});
        setLoading(false);
      }
    });
  }, []); // Dependency on cookieBookmarks

  return { bookmarks, loading };
}

export default useBookmarks;
