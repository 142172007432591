import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useBookmarks from "../hooks/useBookMarks";
import { deleteBookMark, goBookMarkPath } from "../apis/bookmark";
import { useLocation, useNavigate } from "react-router-dom";

const Layout = styled.div`
  height: 100vh;
  padding-inline: 16px;
  background: #ddd;
  overflow-y: scroll;
  padding-top: 45px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
`;

// Styled-components 정의
const SplitViewContainer = styled.div`
  flex: 1;
  padding-bottom: 41px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 6px), 1fr)); // 1행에 최대한 많이, 넘치면 다음 행으로
  column-gap: 12px;
  row-gap: 8px;

  iframe {
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  @media (min-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    grid-template-columns: repeat(auto-fill, minmax(calc(213px), 1fr)); // 너비 768px 이상일 때 패딩 조정

    iframe {
      width: 213px;
    }
  }
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative; // Add position relative
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  @media (min-width: 768px) {
    height: 413px;
    width: 213px;
  }
`;

const Iframe = styled.iframe`
  flex: 1 1 calc(50% - 16px);
  cursor: pointer;
  /* sandbox=""; // 상호작용 제한을 위해 수정 */
  min-height: 219px;
  position: relative;
  pointer-events: none;
`;

const IframeOverlay = styled.div`
  position: absolute; // 절대 위치
  top: 0; // iframe 위에 덮기
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent; // 투명 배경
  z-index: 20; // iframe보다 위에
`;

const IframeText = styled.div`
  color: var(--label-primary, #000);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

const CloseButton = styled.img`
  position: absolute; // Change to absolute
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-weight: bold;
  cursor: pointer;
  z-index: 100;
`;

const TabContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 50px;
  z-index: 100;
  background: white;
  overflow-x: auto;
  justify-content: center;
`;

const SplitViewPage = () => {
  const { bookmarks } = useBookmarks();
  const [iframeSources, setIframeSources] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // 포커스 상태 관리 및 라우팅 처리
    const handleBackButton = () => {
      navigate("/");
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    setIframeSources(
      bookmarks.map((bookmark) => ({
        src: goBookMarkPath(bookmark),
        title: bookmark.query,
      }))
    );
  }, [bookmarks]);

  const handleIframeClick = (src) => {
    window.location.href = src; // iframe의 src로 페이지 이동
  };

  const closeTab = async (index) => {
    const filteredBookmarks = bookmarks.filter((_, i) => i !== index);

    setIframeSources((prevSources) => prevSources.filter((_, i) => i !== index));

    await deleteBookMark(bookmarks[index]);
  };

  return (
    <Layout>
      <SplitViewContainer>
        {iframeSources.map((iframe, index) => (
          <IframeContainer key={index}>
            <CloseButton src="/svg/closeButton.svg" onClick={() => closeTab(index)} />
            <Iframe title={iframe.title} src={iframe.src} className="responsive-iframe" allowfullscreen={true} />
            <IframeOverlay onClick={() => handleIframeClick(iframe.src)} />
            <IframeText>{iframe.title}</IframeText>
          </IframeContainer>
        ))}
      </SplitViewContainer>
      <TabContainer>{iframeSources.length} Bookmarks</TabContainer>
    </Layout>
  );
};

export default SplitViewPage;
